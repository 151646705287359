//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import StyledLink from "./StyledLink.vue";
export default {
  components: { StyledLink },
  name: "ButtonsTray",
  props: {
    buttons: {
      type: [Array, Object],
      default: null,
    },
    invent: {
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["setMenuState", "setMenuData", "setFormId"]),
    formOpen(data, id) {
      if ( data ) {

        this.setMenuState(true);
        this.setFormId(id);
        this.setMenuData(data);
      }
    },
  },
};
